<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <el-row style="margin-bottom:15px;">
                    <el-col :span="6">
                        <div>
                            <el-input placeholder="请输入内容" v-model.trim="search_value" class="input-with-select">
                                <el-select style="width:120px;" v-model="search_type" slot="prepend" placeholder="请选择">
                                    <el-option v-for="item in searchTypeEnum" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </el-col>
                    <el-button style="margin-left:24px;" type="primary" @click.stop="handleSubmit">搜索</el-button>
                    <el-button @click.stop="handleClear">清空</el-button>
                </el-row>
                <el-table :data="tableData">
                    <el-table-column label="客户编号" prop="cuscode" min-width="100"></el-table-column>
                    <el-table-column label="姓名" prop="custName" min-width="120"></el-table-column>
                    <el-table-column label="电话号码" prop="phone" min-width="100">
                        <template slot-scope="scope">
                            <div v-for="(item,index) in scope.row.phoneList"  :key='index' >{{item}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="存在系统" min-width="160" prop="sysName">
                    </el-table-column>
                    <el-table-column label="创建时间" min-width="120" prop="createtime">
                    </el-table-column>
                    <el-table-column label="成交系统" min-width="160" prop="sysName">
                        <template slot-scope="scope">
                            <div v-for="(item,index) in scope.row.custDealList"  :key='index' >{{item.systemName}}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="核算新客户月份" min-width="140" prop="sysName">
                        <template slot-scope="scope">
                            {{scope.row.createtime}}
                        </template>
                    </el-table-column> -->
                    <el-table-column label="操作"
                        fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button>
                            </div>
                        </template>
                    </el-table-column>

                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            tableData: [],
            search_value: "",
            search_type: 1,
            pageNum: 1,
            pageSize: 10,
            total: 0,

            searchTypeEnum: [
                { name: "客户编号", value: 1 },
                { name: "电话号码", value: 2 },
            ],
             auth:{
               delete:false 
            }
        };
    },
    created() {
        this.auth.delete = this.$hasAuthFor("api/channel/deleteCustomer"); //删除
        this.fetchData(); 
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        // 点击表格行操作按钮: 删除
        async handleDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("请先确认各业务系统已删除该客户，否则会造成严重后果！", "温馨提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/customer/deleteCust",
                    method: "post",
                    data: {
                        id: parseInt(data.id)
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        handleSubmit() {
            this.pageNum=1
            this.pageSize=10
            this.fetchData();
        },
        handleClear() {
            this.pageNum=1
            this.pageSize=10
            this.search_value = "";
            this.search_type = 1;
            this.fetchData();
        },
        async fetchData() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/customer/getCustomerList",
                method: "post",
                data: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    search_type: this.search_type,
                    search_value: this.search_value,
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.tableData = res.data.customerList || [];
            }else{
                this.$message({
                    message: res.msg || "获取数据失败",
                    type: "warning"
                });
            }
            const res2 = await this.$axios({
                url: "/api/customer/countAllCustomer",
                method: "post",
                data: {
                    search_type: this.search_type,
                    search_value: this.search_value,
                },
            });
            
            if (res2.code === 2000) {
                this.total = res2.data.count || 0;
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>
</style>